import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import * as AcfLayout from '../components/Acf';
import { PageHeader } from '../components/PageHeader';
import PostList from '../components/Posts/PostList';
import { decodeEntities } from '../utils/helpers';

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.__typename ? item.__typename.replace('WordPressAcf_','') : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The ACF component <strong>"{componentTitle}"</strong> is missing. <span>&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }
};

const BlogPage = (props) => {
  const { data, pageContext, location } = props;
  const { allWordpressPost, categories, wordpressWpSettings, siteSettings } = data;
  const { layout = {} } = data.wordpressPage.acf;
  const { showAuthor } = siteSettings.options
  const { title: siteTitle } = wordpressWpSettings;
  const { edges: posts } = allWordpressPost
  const featuredImage = categories.edges[0] && categories.edges[0].node.featured_image_url ? categories.edges[0].node.featured_image_url : ''
  
  // Experiencing an issue with Yoast NOT sending all data to ACF, so hard coding the title for the blog page
  const staticBlogPageTitle = "Blog » Tips & Industry Insights » Amped Digital Signage";
  return (
    <Layout location={location}>
      <SEO title={decodeEntities(staticBlogPageTitle)} />
      {/* <PageHeader headerTitle="Blog" location={location} headerBackgroundImage={featuredImage} /> */}
      {layout && layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            pageContext={pageContext}
          />
        );
      })}
      <PostList
        posts={posts}
        title="Latest posts"
        pageContext={pageContext}
        categories={categories.edges}
        siteMetadata={wordpressWpSettings}
        pathPrefix={wordpressWpSettings.blogSlug}
        showAuthor={showAuthor}
      />
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export default BlogPage

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    },
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    wordpressPage(title: {eq: "Blog"}) {
      title
      ...AcfQuery
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`